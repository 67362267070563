import { Component, inject, OnInit } from '@angular/core';
import { AppsSwitcherService, ShellSettingsService } from '@hxp/kernel';

@Component({
  selector: 'hxp-onboarding-support-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly _switcherApps = inject(AppsSwitcherService);
  private readonly _themingService = inject(ShellSettingsService);

  readonly accountApps$ = this._switcherApps.accountApps$;

  ngOnInit() {
    this._themingService.forceLightTheme();
  }
}
