import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OnboardingContract } from '@hxp/graphql';
import { HxpToastService } from '@hxp/shared/common';

export type EmailFormContract = Pick<OnboardingContract, 'adminEmail' | 'id' | 'status' | 'contactEmail'>;

export abstract class EmailForm {
  form: UntypedFormGroup;
  updateInProgress = false;
  originalTermEmail: string | null | undefined;
  originalAdminEmail: string | null | undefined;

  abstract canResendEmail: boolean;
  abstract updateEmailButtonText: string;
  abstract resendEmailButtonText: string;

  constructor(
    protected emailFormContract: EmailFormContract,
    protected toastService: HxpToastService,
  ) {
    this.originalAdminEmail = emailFormContract.adminEmail;
    this.originalTermEmail = emailFormContract.contactEmail;

    this.form = new UntypedFormGroup({
      termEmail: new UntypedFormControl(emailFormContract.contactEmail, [Validators.required, Validators.email]),
      adminEmail: new UntypedFormControl(emailFormContract.adminEmail, [Validators.required, Validators.email]),
    });
  }

  abstract emailFieldIsBlank(): boolean;

  onResend() {
    if (this.form.invalid) {
      this.toastService.error('Invalid Email');
      return;
    }

    this.updateInProgress = true;

    this._resendEmail();
  }
  protected abstract _resendEmail(): void;

  onUpdateEmail() {
    if (this.form.invalid) {
      this.toastService.error('Invalid Email');
      return;
    }

    this.updateInProgress = true;

    this._updateEmail();
  }
  protected abstract _updateEmail(): void;

  onUpdateEmailResponse() {
    this.form.reset({ termEmail: this.form.get('termEmail')?.value, adminEmail: this.form.get('adminEmail')?.value });
    this.originalTermEmail = this.form.get('termEmail')?.value;
    this.originalAdminEmail = this.form.get('adminEmail')?.value;
  }

  checkOriginalAdminEmail(adminEmail: string): void {
    // first portion handles if the values are different but both are falsy (null and empty should be treated the same)
    // second portion handles difference in text
    if ((!adminEmail && !this.originalAdminEmail) || adminEmail === this.originalAdminEmail) {
      this.form.reset({ termEmail: this.form.get('termEmail')?.value, adminEmail: this.form.get('adminEmail')?.value });
    }
  }

  checkOriginalTermEmail(termEmail: string): void {
    // first portion handles if the values are different but both are falsy (null and empty should be treated the same)
    // second portion handles difference in text
    if ((!termEmail && !this.originalTermEmail) || termEmail === this.originalTermEmail) {
      this.form.reset({ termEmail: this.form.get('termEmail')?.value, adminEmail: this.form.get('adminEmail')?.value });
    }
  }
}
