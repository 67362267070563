import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { HyContentListModule, HyProgressStepperModule, HyResizableSideBarModule, HyToastModule } from '@hyland/ui';

import { MatSidenavModule } from '@angular/material/sidenav';
import { GraphQLModule, KernelComponentsModule } from '@hxp/kernel';
import { CONFIG_PROVIDERS } from '@hxp/shared/configuration';

import { HyShellModule } from '@hyland/ui-shell';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { ContentCannotBeViewedView } from './views/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { ContractView } from './views/contracts/contract/contract.view';
import { SearchView } from './views/contracts/search/search.view';

import { ContractInfoComponent } from './components/contract-info/contract-info.component';
import { ContractLogComponent } from './components/contract-log/contract-log.component';
import { ContractStatusProgressComponent } from './components/contract-status-progress/contract-status-progress.component';
import { ContractSubscriptionsComponent } from './components/contract-subscriptions/contract-subscriptions.component';
import { ContractsGridComponent } from './components/contracts-grid/contracts-grid.component';
import { ContractStatusPipe } from './pipes/contract-status.pipe';
import { EnvironmentStatusPipe } from './pipes/environment-status.pipe';
import { RecentContractsView } from './views/contracts/recent-contracts/recent-contracts';
import { SideNavView } from './views/contracts/side-nav/side-nav.view';
import { UnauthorizedView } from './views/unauthorized/unauthorized.view';

@NgModule({
  declarations: [
    AppComponent,
    SearchView,
    ContractView,
    ContractInfoComponent,
    ContractLogComponent,
    ContractsGridComponent,
    ContractSubscriptionsComponent,
    ContractStatusProgressComponent,
    ContractStatusPipe,
    EnvironmentStatusPipe,
    ContentCannotBeViewedView,
    RecentContractsView,
    SideNavView,
    UnauthorizedView,
  ],
  bootstrap: [AppComponent],
  imports: [
    HyShellModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    GraphQLModule,
    HyContentListModule,
    HyProgressStepperModule,
    HyResizableSideBarModule,
    HyToastModule,
    KernelComponentsModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [...CONFIG_PROVIDERS, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
